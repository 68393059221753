<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn dark v-bind="attrs" v-on="on"> Set Referral Bonus </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Set Referral Bonus
        </v-card-title>

        <v-container>
          <v-row>
            <v-col cols="12" sm="12">
              <v-text-field
                v-if="ReferralBonus"
                type="number"
                v-model="ReferralBonus.amount"
                label="$Amount"
                filled
              ></v-text-field>
              <v-text-field
                v-else
                type="number"
                v-model="amount"
                label="$Amount"
                filled
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>

        <v-divider></v-divider>

        <v-card-actions class="pa-3">
          <v-spacer></v-spacer>
          <v-btn v-if="!ReferralBonus" color="pink" @click="SetReferralBonus()">
            Process
          </v-btn>
          <v-btn v-else color="pink" @click="UpdateReferralBonus()">
            Process
          </v-btn>
          <v-btn color="primary" text @click="dialog = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
    
    <script>
export default {
  data() {
    return {
      dialog: false,
      amount: "",
    };
  },
  computed: {
    getToken() {
      return this.$store.getters.getToken;
    },
    ReferralBonus() {
      return this.$store.getters.ReferralBonus;
    },
  },
  methods: {
    SetReferralBonus() {
      let payload = {
        token: this.getToken,
        amount: this.amount,
      };
      this.$store.dispatch("SetReferralBonus", payload);
      this.dialog = false;
    },
    UpdateReferralBonus() {
      let payload = {
        token: this.getToken,
        amount: this.ReferralBonus.amount,
      };
      this.$store.dispatch("UpdateReferralBonus", payload);
      this.dialog = false;
    },
  },
};
</script>