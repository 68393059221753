<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ma-2"
          v-if="user.status"
          color="pink"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Disable
        </v-btn>
        <v-btn class="ma-2" v-else color="info" dark v-bind="attrs" v-on="on">
          Enable
        </v-btn>
      </template>

      <v-card>
        <v-card-title v-if="user.status" class="text-h5 grey lighten-2">
          Disable User
        </v-card-title>
        <v-card-title v-else class="text-h5 grey lighten-2">
          Enable User
        </v-card-title>

        <v-card-text>
          Are you sure you want to
          <span v-if="user.status">Disable</span>
          <span v-else>Enable </span>
          <b> {{ user.userName }}</b>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="pink" @click="Activate()"> Process </v-btn>
          <v-btn color="primary" text @click="dialog = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
    <script>
export default {
  props: ["user", "userType"],
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    getToken() {
      return this.$store.getters.getToken;
    },
  },
  methods: {
    Activate() {
      let payload = {
        token: this.getToken,
        userId: this.user._id,
        action: !this.user.status,
      };
      if (this.userType == "User") {
        this.$store.dispatch("EnableOrDisableUser", payload);
      } else {
        this.$store.dispatch("EnableOrDisableAdmin", payload);
      }
      this.dialog = false;
    },
  },
};
</script>