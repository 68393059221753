<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="600">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          Login History
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Login History
        </v-card-title>

        <v-simple-table dark>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">IP</th>
                <th class="text-left">UserAgent</th>
                <th class="text-left">Date</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in getLoginHistory" :key="item.name">
                <td>{{ item.ip }}</td>
                <td>{{ item.userAgent }}</td>
                <td>{{ item.createdAt }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
  <script>
export default {
  props: ["userType", "user"],
  data() {
    return {
      dialog: false,
      page: 1,
    };
  },
  computed: {
    getLoginHistory() {
      return this.$store.getters.getLoginHistory;
    },
    getToken() {
      return this.$store.getters.getToken;
    },
  },
  watch: {
    dialog: function (val) {
      if (val) {
        let payload = {
          token: this.getToken,
          page: this.page,
          userId: this.user._id,
        };
        if (this.userType == "User") {
          this.$store.dispatch("LoginHistory", payload);
        } else {
          this.$store.dispatch("AdminLoginHistory", payload);
        }
      } else {
        this.$store.commit("setLoginHistory", []);
      }
    },
  },
};
</script>