<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="pink" dark v-bind="attrs" v-on="on"> Add Admin </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Add Admin </v-card-title>

        <v-container>
          <v-row>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="userName"
                label="User Name"
                filled
              ></v-text-field>
              <v-text-field v-model="email" label="Email" filled></v-text-field>
              <v-text-field
                v-model="password"
                label="Password"
                filled
                type="password"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>

        <v-divider></v-divider>

        <v-card-actions class="pa-3">
          <v-spacer></v-spacer>
          <v-btn color="pink" @click="AddStaff()"> Process </v-btn>
          <v-btn color="primary" text @click="dialog = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      dialog: false,
      email: "",
      password: "",
      userName: "",
    };
  },
  computed: {
    getToken() {
      return this.$store.getters.getToken;
    },
  },
  methods: {
    AddStaff() {
      let payload = {
        token: this.getToken,
        email: this.email,
        password: this.password,
        userName: this.userName,
      };
      this.$store.dispatch("AddStaff", payload);
      this.dialog = false;
    },
  },
};
</script>